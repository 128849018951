/*! Basic Scripts 2020-04-20 */

/*----------------------------------------
  Fixed Header & shrink
----------------------------------------*/
$(document).ready(function(){
  $('#header').scrollToFixed();
});

$(document).on("scroll", function(){
  if ($(document).scrollTop() > 100){
    $("#header").addClass("shrink");
  } else {
    $("#header").removeClass("shrink");
  }
});


/*----------------------------------------
  Fixed Footer
----------------------------------------*/
$(document).ready(function(){
  function fullmainwindow(){
    var intMinMainHeight = jQuery(window).height() - $('#header').outerHeight() - $('#footer').outerHeight();
    if ($('#main').outerHeight() < intMinMainHeight) {
      $("#main").css('minHeight', intMinMainHeight);
    }
  }
  fullmainwindow();
  // Run the function in case of window resize
  jQuery(window).resize(function() {
    fullmainwindow();
  });
});


/*----------------------------------------
  Form width placeholder
----------------------------------------*/
$("form :input").each(function(index, elem) {
  var eId = $(elem).attr("id");
  var label = null;
  if (eId && (label = $(elem).parents("form").find("label[for=" + eId + "]")).length == 1) {
    if (!$(elem).hasClass("captcha")) {
      var placeholder = $(label).text();
      var tmp_placeholder = placeholder.replace("Pflichtfeld", "");
      var new_placeholder = tmp_placeholder.trim();
      $(elem).attr("placeholder", new_placeholder);
      $(label).addClass('hide');
    }
  }
});
$("form :input").focus(function() {
  $(this).parent(".widget").each(function() {
    $("label", this).removeClass("hide");
    $("label", this).addClass("active");
  });
}).blur(function() {
  if ($(this).val() === "") {
    $(this).parent(".widget").each(function() {
      $("label", this).removeClass("active");
      $("label", this).addClass("hide");
    });
  } else {
    $(this).parent(".widget").each(function() {
      $("label", this).removeClass("active");
    });
  }
});


/*----------------------------------------
  Top-Link
----------------------------------------*/
$(document).ready(function() {
  $('div.toplink').hide();
  $(window).scroll(function(){
    var value = 200;
    var scrolling = $(window).scrollTop();
    if (scrolling > value) {
      $('div.toplink').fadeIn();
    } else {
      $('div.toplink').fadeOut();
    }
  });
  $('div.toplink').click(function(){
    $('html, body').animate({scrollTop:'0px'}, 800);
    return false;
  });
});


/*----------------------------------------
  Onepager-Toggler
----------------------------------------*/
$(document).ready(function() {
  $('.onepagebar-toggler').click(function() {
    $(".onepagebar-toggler").toggleClass("onepagebar-toggler--active");
    if ($(".onepagebar-toggler").hasClass("onepagebar-toggler--active")) {
      document.getElementById("onepagebar-nav").style.height = "100%";
    } else {
      document.getElementById("onepagebar-nav").style.height = "0%";
    }
  });
  $('.onepagebar-overlay-content li a').click(function() {
    $(".onepagebar-toggler").toggleClass("onepagebar-toggler--active");
    document.getElementById("onepagebar-nav").style.height = "0%";
  });
});


/*----------------------------------------
  Onepager-Scrolling
----------------------------------------*/
$(document).ready(function() {
  var el = $('a[href*="#"]').not('[href="#"]').not('[href="#0"]');
  var scrollSpeed = 800;
  var uri = window.location.href.split("#")[0];

  el.click(function(event) {
    // check if On-page links
    if (
      location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') &&
      location.hostname == this.hostname
    ) {
      // Figure out element to scroll to
      var target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
      // Does a scroll target exist?
      if (target.length) {
        // Only prevent default if animation is actually gonna happen
        event.preventDefault();
        $('html, body').animate({
          scrollTop: target.offset().top - 100
        }, scrollSpeed);
      }
    }
  });

  // set nav active when scrolling
  var navActive = function(section) {
    var $el = $('.onepage_navigation__list');

    $el.find('li.active').removeClass('active');
    $el.each(function() {
      $(this).find('a[data-onepagelink="' + section + '"]').parent().addClass('active');
    });
  };

  var navigationSection = function() {
    var $section = $('.mod_article');

    $section.waypoint(function(direction) {

      if (direction === 'down') {
        if ($(this.element).hasClass('onepage_article')) {
          // change url to active link
          navActive(this.element.id);

          // change url to active link
          if (typeof(window.history.pushState) == 'function') {
            history.pushState("", "", uri + '#' + this.element.id);
          }
        }
      }
    }, {
      offset: '50%'
    });

    $section.waypoint(function(direction) {
      if (direction === 'up') {
        var previousWaypoint = this.previous();

        if ($(this.element).hasClass('onepage_article')) {
          // change url to active link
          navActive(this.element.id);
          history.pushState("", "", uri + '#' + this.element.id);
        } else {
          navActive(previousWaypoint.element.id);
          history.pushState("", "", uri + '#' + previousWaypoint.element.id);
        }
      }
    }, {
      offset: function() {
        return (this.context.innerHeight() / 2) - this.element.offsetHeight; // offset 50% from bottom
      },
      group: $section
    });
  };

  $(function() {
    navigationSection();
  });

});


/*----------------------------------------
  Lightgallery
----------------------------------------*/
$(document).ready(function() {
  $('#lightgallery-1').lightGallery({
    mode: 'lg-fade',
    thumbnail:true,
    animateThumb: true,
    showThumbByDefault: true,
    download:false,
    share:false
  });

  /* Blur */
  var width = jQuery(window).width();
  if (width > 1280) {

    var $lightgallery = $('#lightgallery-1');
    $lightgallery.lightGallery();
    var data = $lightgallery.data('lightGallery');

    $lightgallery.on('onBeforeSlide.lg', function(event, prevIndex, index){
      var img = data.$items.eq(index).attr('data-src');
      $('.lg-backdrop').css('background-image', 'url('+img+')');
    });
  }
});
